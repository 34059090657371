<template>
	<div :style="bg">
		<Header :title="$t('setting.addAddress')"></Header>
		
		<div class="column-center-content main-content">
			<div class="form-content">
				<label class="form-title">{{$t('setting.addressName')}}</label>
				<v-text-field :placeholder="$t('setting.addressName_hint')" style="width: 100%; padding-top: 5px;" v-model="name"></v-text-field>
			</div>
			
			<div class="form-content">
				<label class="form-title">{{$t('setting.address')}}</label>
				<v-text-field :placeholder="$t('setting.address_hint')" style="width: 100%; padding-top: 5px;" v-model="address"></v-text-field>
			</div>
			
			<div class="form-content row-content">
				<v-text-field :placeholder="$t('login.tac_hint')" color="success" v-model="code">
					<v-icon style="width: 20px; margin-right: 10px; color:#FF0000;" slot="prepend" >
						fa fa-envelope-open-o
					</v-icon>
				</v-text-field>
				<mt-button @click="sendCode" type="danger" plain style="height: 30px; border-radius: 15px; font-size: 10px;">{{$t('login.getTac')}}</mt-button>
			</div>
		</div>
		
		<div class="btn-content">
			<v-btn rounded block @click="addAddress" style="background-color: #009245; color: white; height: 40px;">
				{{$t('common.submit')}}
			</v-btn>
		</div>
	</div>
</template>

<script>
	import "@/styles/common.css"
	import { Indicator } from 'mint-ui';
	import { Toast } from 'mint-ui';
	import Header from '../../../components/NavHeader.vue'
	
	export default {
		name: 'AddressAdd',
		components: {
			Header
		},
		data() { 
			return {
				bg : {
					height: "100vh",
					width: "100%",
					backgroundImage: "url(" + require("../../../assets/login/new-bg.png") + ")",
					backgroundRepeat: "no-repeat",
					backgroundPosition: "fixed",
					backgroundSize: "100% 100%",
					display: "flex",
					flexDirection: "column",
					alignItems: "center",
					overflow: "auto"
				},
				name: '',
				address: '',
				code: ''
			}
		},
		methods: {
			
			sendCode() {
				Indicator.open({
					text: '',
					spinnerType: 'fading-circle'
				});
				
				let phone = JSON.parse(localStorage.getItem('userInfo')).phone;
				let params = {
					phone: phone
				}
				this.$request.sendCode(
					params
				).then(()=>{
					Indicator.close();
				});
			},
			
			addAddress() {
				let name = this.$data.name;
				let address= this.$data.address;
				let code = this.$data.code;
				
				if(null == name || '' == name) {
					Toast({
						message: this.$i18n.t("setting.addressName_hint"),
						position: 'bottom'
					});
					return;
				}
				if(null == address || '' == address) {
					Toast({
						message: this.$i18n.t("setting.address_hint"),
						position: 'bottom'
					});
					return;
				}
				if(null == code || '' == code) {
					Toast({
						message: this.$i18n.t("login.tac_hint"),
						position: 'bottom'
					});
					return;
				}
					
				let params = {
					address: address,
					code: code,
					name: name
				};
				
				this.$request.setWithdrawAddress(
					params
				).then(()=>{
					// 跳转首页
					this.$router.push({
						name: 'Main'
					})
				});
			}
		}
	}
</script>

<style>
</style>
